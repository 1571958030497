<template>
  <div class="space-y-4">
    <asom-modal
      title="Create Cash Declaration Form"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>

    <asom-alert
      class="md:col-span-2 mb-4"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <asom-card>
      <div class="flex flex-col" v-if="formData.sourceOfCash !== '4'">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div class="overflow-hidden border-gray-400 sm:rounded-lg">
              <table class="table-auto w-full bg-gray-200">
                <caption class="hidden"></caption>
                <thead class="hidden">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="grid grid-cols-auto xl:grid-cols-2">
                    <td class="align-top">
                      <div class="flex flex-col">
                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div
                            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                          >
                            <div
                              class="overflow-hidden border-b border-gray-200 sm:rounded-lg"
                            >
                              <table
                                class="min-w-full divide-y divide-gray-200"
                              >
                                <caption class="hidden"></caption>
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Denominations
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      No. of Notes
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Value
                                    </th>
                                  </tr>
                                </thead>
                                <tbody
                                  class="bg-white divide-y divide-gray-200"
                                >
                                  <tr>
                                    <td class="denominations">$2</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count2Dollars')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count2Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count2Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          2,
                                          formData.count2Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$5</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count5Dollars')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count5Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count5Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          5,
                                          formData.count5Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$10</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count10Dollars')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count10Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count10Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          10,
                                          formData.count10Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$20</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count20Dollars')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count20Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count20Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          20,
                                          formData.count20Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$50</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count50Dollars')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count50Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count50Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          50,
                                          formData.count50Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$100</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates(
                                            'formData.count100Dollars'
                                          )
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count100Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count100Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          100,
                                          formData.count100Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$1000</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates(
                                            'formData.count1000Dollars'
                                          )
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count1000Dollars
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count1000Dollars'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          1000,
                                          formData.count1000Dollars
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$1 (Note)</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count1DollarNote')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="
                                            formData.count1DollarNote
                                          "
                                          :state="
                                            inputStates(
                                              'formData.count1DollarNote'
                                            )
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          1,
                                          formData.count1DollarNote
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="align-top">
                      <div class="flex flex-col flex-grow">
                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div
                            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                          >
                            <div
                              class="overflow-hidden border-b border-gray-200 sm:rounded-lg"
                            >
                              <table
                                class="min-w-full divide-y divide-gray-200"
                              >
                                <caption class="hidden"></caption>
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Denominations
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      No. of Coins
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Value
                                    </th>
                                  </tr>
                                </thead>
                                <tbody
                                  class="bg-white divide-y divide-gray-200"
                                >
                                  <tr>
                                    <td class="denominations">1¢</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count1Cents')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count1Cents"
                                          :state="
                                            inputStates('formData.count1Cents')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          0.01,
                                          formData.count1Cents
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">2¢</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count2Cents')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count2Cents"
                                          :state="
                                            inputStates('formData.count2Cents')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          0.02,
                                          formData.count2Cents
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">5¢</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count5Cents')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count5Cents"
                                          :state="
                                            inputStates('formData.count5Cents')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          0.05,
                                          formData.count5Cents
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">10¢</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count10Cents')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count10Cents"
                                          :state="
                                            inputStates('formData.count10Cents')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          0.1,
                                          formData.count10Cents
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">20¢</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count20Cents')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count20Cents"
                                          :state="
                                            inputStates('formData.count20Cents')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          0.2,
                                          formData.count20Cents
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">50¢</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count50Cents')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count50Cents"
                                          :state="
                                            inputStates('formData.count50Cents')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          0.5,
                                          formData.count50Cents
                                        )
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="denominations">$1</td>
                                    <td class="noOfNotes">
                                      <asom-form-field
                                        required
                                        :state="
                                          inputStates('formData.count1Dollar')
                                        "
                                      >
                                        <asom-input-text
                                          type="number"
                                          min="0"
                                          v-model.number="formData.count1Dollar"
                                          :state="
                                            inputStates('formData.count1Dollar')
                                          "
                                      /></asom-form-field>
                                    </td>
                                    <td class="value">
                                      {{
                                        getValueOfNotes(
                                          1,
                                          formData.count1Dollar
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">
                      <div class="flex flex-col items-center p-4">
                        <div class="items-center">
                          <span class="font-sans text-gray-500 font-bold pr-2"
                            >Total cash in Cash Bag</span
                          >
                          <span
                            class="font-sans text-gray-500 font-bold text-2xl"
                            >${{ totalAmount }}</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4 pt-8 pb-12">
        <asom-form-field
          label="TE Bag S/No"
          required
          :state="inputStates('formData.bagNo')"
          error="TE Bag Number is required"
        >
          <scan-code
            v-model="formData.bagNo"
            :state="inputStates('formData.bagNo')"
            @error="showCashBagError = true"
          />
        </asom-form-field>
        <div></div>
        <asom-form-field
          :label="'Witness Officer'"
          required
          :state="inputStates('formData.witnessId')"
          :error="
            v$.formData.witnessId.$error
              ? v$.formData.witnessId.$errors[0].$message
              : null
          "
        >
          <asom-input-select
            :state="inputStates('formData.witnessId')"
            v-model="formData.witnessId"
            :loading="isLoadingOfficers"
            :options="officerOptions"
            @search-change="queryOfficerList"
            placeholder="Type to search for officer"
          />
        </asom-form-field>
        <asom-form-field
          label="Source of Cash"
          required
          :state="inputStates('formData.sourceOfCash')"
          error="Select a source of Cash"
        >
          <asom-input-select
            :objectModelValue="false"
            :state="inputStates('formData.sourceOfCash')"
            v-model="formData.sourceOfCash"
            :options="sourceOfCashOptions"
          />
        </asom-form-field>
        <asom-form-field
          :required="formData.sourceOfCash === '5' ? true : false"
          label="Remarks"
          :state="inputStates('formData.remarks')"
          error="Remark is required for cash from other sources"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
        <asom-form-field
          v-if="isPSMRequired"
          label="PSM"
          required
          :state="inputStates('formData.psmId')"
          error="PSM is required"
        >
          <asom-input-select
            :objectModelValue="false"
            v-model="formData.psmId"
            :state="inputStates('formData.psmId')"
            :options="psmOptions"
          />
        </asom-form-field>
        <asom-form-field
          v-if="['2', '3', '4'].includes(formData.sourceOfCash)"
          label="Selected Transaction No(s)."
          required
          error="One or more transactions required"
        >
          <p class="font-semibold ml-2" v-for="(No, i) in selectedTransactionNos" :key="i">
            {{No}}
          </p>
        </asom-form-field>
        <div v-if="['5', null].includes(formData.sourceOfCash)"></div>
        <asom-form-field
        label="Bagger Signature"
        required
        :state="inputStates('formData.baggerSignature')"
        error="Signature is required"
        >
          <asom-signature-pad v-model="formData.baggerSignature" :width="400" />
        </asom-form-field>
      </form>

      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.push('/cash-mgnt/cash-declaration-form')"
        />
        <asom-button
          text="Confirm"
          @click="submitClicked"
          :loading="isSubmitting"
        />
      </div>
    </asom-card>
    <asom-modal
      title="Cash Bag Error!"
      v-model="showCashBagError"
      :dismissible="false"
    >
      <p>Barcode Detection Error! Please try again</p>
      <div class="flex flex-row-reverse pt-4">
        <div>
          <asom-button
            @click="closeCashBagError"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title='Notification'
      v-model="showSourceOfCashConfrim"
      :dismissible="false"
      :maskClosable="false"
    >
      <p>You have selected "Others" as the source of cash. Please be advised that this choice requires careful consideration.</p>
      <div class="flex flex-row-reverse pt-4">
        <div>
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="sourceOfCashConfrimCancel"
          />
          <asom-button
            text="Confrim"
            class="ml-2"
            @click="sourceOfCashConfrimOk"
          />
        </div>
      </div>
    </asom-modal>
    <transaction-selection
      v-model="showTransactionSelection"
      :selected-source="formData.sourceOfCash"
      @selectedTransactions="setTransactions($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import get from "lodash.get";
import { required, numeric, minLength, helpers } from "@vuelidate/validators";
import { listUsers } from "@/services/user.service";
import { createCashDeclarationForm } from "../../../services/cashManagement.service";
import TransactionSelection from "./TransactionSelection";
import ScanCode from "../_ScanCode.vue";
import { formatCurrency, multiply } from "../../../helpers/numbers";
import { imageDataToBlob } from "../../../helpers/imageDataToBlob";
import { uploadFile } from "../../../services/file.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  name: "CashDeclarationFormCreate",
  components: {
    TransactionSelection,
    ScanCode,
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      officerOptions: [],
      isLoadingOfficers: false,
      showCashBagError: false,
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,
      formData: {
        count1Cents: 0,
        count2Cents: 0,
        count5Cents: 0,
        count10Cents: 0,
        count20Cents: 0,
        count50Cents: 0,
        count1Dollar: 0,
        count1DollarNote: 0,
        count2Dollars: 0,
        count5Dollars: 0,
        count10Dollars: 0,
        count20Dollars: 0,
        count50Dollars: 0,
        count100Dollars: 0,
        count1000Dollars: 0,
        witnessId: null,
        psmId: null,
        remarks: "",
        bagNo: "",
        sourceOfCash: null,
        baggerSignature: null,
        baggerSignatureId: null,
      },
      cashFloatTransactionIds: [],
      psmTransactionIds: [],
      preEncodedTicketTransactionIds: [],
      coinFlushFormIds: [],
      sourceOfCashOptions: [
        { value: "1", label: "PSM" },
        { value: "2", label: "Pre Encoded Ticket" },
        { value: "3", label: "Manual Change" },
        { value: "4", label: "Coin Flush" },
        { value: "5", label: "Others" },
      ],
      showTransactionSelection: false,
      selectedTransactionNos: [],
      showSourceOfCashConfrim: false,
    };
  },
  validations() {
    const isNotSame = (val) => {
      return get(val, "value", null) !== this.userId;
    };
    return {
      formData: {
        count1Cents: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count2Cents: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count5Cents: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count10Cents: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count20Cents: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count50Cents: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count1Dollar: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count1DollarNote: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count2Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count5Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count10Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count20Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count50Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count100Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        count1000Dollars: {
          required,
          numeric,
          minLength: minLength(1),
        },
        witnessId: {
          required: helpers.withMessage("Select an officer", required),
          sameAsRawValue: helpers.withMessage(
            "Logged in officer cannot be assigned as a witness",
            isNotSame
          ),
        },
        psmId: this.formData.sourceOfCash === "1" ? { required } : {},
        sourceOfCash: { required },
        bagNo: { required },
        remarks: this.formData.sourceOfCash === "5" ? { required } : {},
        baggerSignature: { required },
      },
    };
  },
  mounted() {
    this.v$ && this.v$.$reset();
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      userId: "auth/userId",
    }),
    isPSMRequired() {
      return this.formData.sourceOfCash === "1";
    },
    psmOptions() {
      const psmList = this.$store.getters[
        "cashManagement/stationCashAccounts/getPSMOfPSC"
      ](this.pscId);
      return psmList.map((psm) => ({
        value: psm.stationPSMId,
        label: psm.name,
      }));
    },
    breadcrumbs() {
      return [
        { text: "Home", href: "#" },
        { text: "Cash Management", href: "#/cash_management" },
        {
          text: "Create Cash Declaration Form",
          href: "#/cash_declaration_form",
        },
      ];
    },
    totalOfNotes() {
      return (
        this.formData.count1DollarNote * 1 +
        this.formData.count2Dollars * 2 +
        this.formData.count5Dollars * 5 +
        this.formData.count10Dollars * 10 +
        this.formData.count20Dollars * 20 +
        this.formData.count50Dollars * 50 +
        this.formData.count100Dollars * 100 +
        this.formData.count1000Dollars * 1000
      );
    },
    totalOfCoins() {
      return (
        ((this.formData.count1Cents * 1 +
          this.formData.count2Cents * 2 +
          this.formData.count5Cents * 5 +
          this.formData.count10Cents * 10 +
          this.formData.count20Cents * 20 +
          this.formData.count50Cents * 50) /
        100) + this.formData.count1Dollar * 1
      );
    },
    totalAmount() {
      return this.totalOfNotes + this.totalOfCoins;
    },
    shouldShowTransactionSelection() {
      return this.formData.sourceOfCash == this.sourceOfCashOptions[1].value ||
        this.formData.sourceOfCash == this.sourceOfCashOptions[2].value ||
        this.formData.sourceOfCash == this.sourceOfCashOptions[3].value
    }
  },
  watch: {
    "shouldShowTransactionSelection": function (newVal) {
      if (!this.showTransactionSelection && newVal) {
        this.showTransactionSelection = true;
      }
      this.selectedTransactionNos = [];
    },
    "showTransactionSelection": function(newVal) {
      if (!newVal &&
        this.shouldShowTransactionSelection &&
        this.selectedTransactionNos.length === 0
      ) {
        this.formData.sourceOfCash = null;
      }
    },
    "formData.sourceOfCash": async function (newVal, oldVal) {
      if (newVal === "5") {
        this.openSourceOfCashConfrim((confrimed) => {
          this.formData.sourceOfCash = confrimed ? newVal : oldVal;
          this.closeSourceOfCashConfrim();
        })
      }
    }
  },
  methods: {
    async queryOfficerList(query) {
      this.isLoadingOfficers = true;
      if (!query) {
        this.isLoadingOfficers = false;
        this.officerOptions = [];
      }
      const result = await listUsers({ search: query.trim() });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficers = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name, personNo }) => ({
            label: `${name} (${personNo})`,
            value: id,
          })
        );
      } else {
        this.isLoadingOfficers = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    setTransactions(selectedTransactions) {
      let ids = selectedTransactions.map((log) => log.transactionId);
      this.selectedTransactionNos = [];
      selectedTransactions.forEach((log) => {
        if(get(log, "referenceNo"))
          this.selectedTransactionNos.push(get(log, "referenceNo"))
      });
      selectedTransactions.forEach((log) => {
        if(get(log, "transactionNo"))
          this.selectedTransactionNos.push(get(log, "transactionNo"))
      });
      if (this.formData.sourceOfCash == "1") this.psmTransactionIds = ids;
      else if (this.formData.sourceOfCash == "2")
        this.preEncodedTicketTransactionIds = ids;
      else if (this.formData.sourceOfCash == "3")
        this.cashFloatTransactionIds = ids;
      else if (this.formData.sourceOfCash === "4")
        this.coinFlushFormIds = selectedTransactions.map(({ id }) => id);
    },
    getValueOfNotes(value, amount) {
      return formatCurrency(multiply(value, amount));
    },
    closeCashBagError() {
      this.showCashBagError = false;
    },
    async loadOfficerOfStation() {
      this.isLoading = true;
      const result = await listUsers({ skip: 0 });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        );
        this.isLoading = false;
        this.error = null;
      } else {
        this.officerOptions = [];
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const isSignatureUploaded = this.formData.baggerSignatureId !== null;
      if (!isSignatureUploaded) {
        const signatureBlob = imageDataToBlob(
          this.formData.baggerSignature,
          "bagger_signature"
        );
        const uploadResult = await uploadFile([signatureBlob], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
        if (!uploadResult.success) {
          this.error = uploadResult.payload;
          this.isSubmitting = false;
          this.$scrollTop();
          return;
        }
        this.formData.baggerSignatureId = get(
          uploadResult,
          "payload.files.0.fileId"
        );
      }
      const result = await createCashDeclarationForm({
        sourceOfCash: this.formData.sourceOfCash,
        count1Cents: this.formData.count1Cents,
        count2Cents: this.formData.count2Cents,
        count5Cents: this.formData.count5Cents,
        count10Cents: this.formData.count10Cents,
        count20Cents: this.formData.count20Cents,
        count50Cents: this.formData.count50Cents,
        count1Dollar: this.formData.count1Dollar,
        count1DollarNote: this.formData.count1DollarNote,
        count2Dollars: this.formData.count2Dollars,
        count5Dollars: this.formData.count5Dollars,
        count10Dollars: this.formData.count10Dollars,
        count20Dollars: this.formData.count20Dollars,
        count50Dollars: this.formData.count50Dollars,
        count100Dollars: this.formData.count100Dollars,
        count1000Dollars: this.formData.count1000Dollars,
        teBagNo: this.formData.bagNo,
        psmTransactionIds:
          this.formData.sourceOfCash == "1" ? this.psmTransactionIds : [],
        preEncodedTicketTransactionIds:
          this.formData.sourceOfCash == "2"
            ? this.preEncodedTicketTransactionIds
            : [],
        cashFloatTransactionIds:
          this.formData.sourceOfCash == "3" ? this.cashFloatTransactionIds : [],
        coinFlushFormId:
          this.formData.sourceOfCash == "4"
            ? get(this.coinFlushFormIds, "0", null)
            : null,
        accountId: this.pscId,
        witnessId: get(this.formData.witnessId, "value", null),
        psmId: this.formData.sourceOfCash == "1" ? this.formData.psmId : null,
        remarks: this.formData.remarks,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
        baggerSignatureId: this.formData.baggerSignatureId,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push("/cash-mgnt/cash-declaration-form");
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
    closeSourceOfCashConfrim() {
      this.showSourceOfCashConfrim = false;
      delete this._sourceOfCashConfrimCallback;
    },
    openSourceOfCashConfrim(callback) {
      this.showSourceOfCashConfrim = true;
      this._sourceOfCashConfrimCallback = callback;
    },
    sourceOfCashConfrimCancel() {
      this._sourceOfCashConfrimCallback?.(false);
    },
    sourceOfCashConfrimOk() {
      this._sourceOfCashConfrimCallback?.(true);
    }
  },
};
</script>

<style scoped>
.denominations {
  @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900;
}
.noOfNotes {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.totalNotes {
  @apply justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
}
.value {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.bold-text {
  @apply text-gray-500 font-bold;
}
</style>
